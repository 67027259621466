import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';

import { ExamBlock } from '../ExamBlock';

export default function AllPaidExamList() {
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);
  const dispatch = useDispatch()

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    AdsGETApiAuth({
      ...perPage,
    }, slugs.db_slug_chapter_wise_exam)
      .then((res) => {
        setAllExamList(res.data)
        setReload(false)
        setPagesLength(res.headers["x-wp-totalpages"])
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())

      })
  }
  let myarray = []
  for (let i = 0; i < pagesLength; i++)
    myarray.push(
      <div key={i} style={{ margin: "3px" }}>
        <button onClick={() => {
          setPages(i + 1)
          setAllExamList1([])
          fetchExam(setAllExamList1, { page: i + 1, per_page: 20, order: "asc" })
        }}
          style={{ border: "0", padding: "7px", backgroundColor: pages == i + 1 ? "#0d02a6" : "#f00", color: "#fff", borderRadius: "5px" }}
        >
          {i + 1}
        </button>
      </div>
    )
  const location = useLocation()
  useEffect(() => {
    if (!location.pathname.slice(11).includes("topik"))
      fetchExam(setAllExamList1, {
        per_page: 200, order: "asc",
        _fields: "id,title,slug,meta.available_unavailable,meta.exam_question_block_student,meta.featured_image"
      })
    else
      setReload(false)
    console.log(location.pathname.slice(11))
  }, [])


  return (
    <div>
      <>
        {AllExamList1.length !== 0 &&
          <>
            {AllExamList1[0].meta.exam_question_block_student == undefined &&
              <div style={{ width: "100%", padding: "7px", color: "#fff" }}>
                <Link className="Link" to="/profile" style={{ color: "#000" }}>
                  Verify your user first to start with exam
                </Link>
              </div>
            }
          </>
        }
      </>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            <h2>All Chapters</h2>
            {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center" style={{ padding: "0 5px" }}>
                <ExamBlock item={item} examSlug={slugs.start_chapter_wise_exam} />
              </div>
            ))}
          </div>

          {pagesLength !== "1" &&
            <div className="" style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              {myarray}
            </div>
          }
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}